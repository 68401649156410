// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

/**
 * https: //github.com/stimulusjs/stimulus/issues/226
 */
const application = Application.start()

application.warnings = true
application.debug = false
window.Stimulus = application

const contextSignedOut = require.context('packs/signed_out/controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(contextSignedOut))

const contextShared = require.context('packs/shared/controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(contextShared))
